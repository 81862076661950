@import url("./fonts/fonts.css");

:root {
  --green-color: #3ac360;
  --dark-green-color: #173f35;
  --white-color: #ffffff;
  --dark-color: #202121;
  --gray-color: #fbfbfb;
  --old-gray-color: #f3f6f9;
  --light-gray-color: #eff2f5;
  --red-color: #a6192e;
  --black-color: #000;
  --gray-footer: #85979b;
}

body {
  margin: 0;
  color: var(--dark-color);
  font-family: "Px Grotesk", "Inter", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  /* font-family: "Poppins", sans-serif; */
}

p {
  line-height: 26px;
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Px Grotesk", "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.children {
  flex: 1;
  min-height: calc(
    100vh - 50px - 120px
  ); /* Odjąć wysokość TopBanner, Menu i Footer */
}
