.prompt {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid var(--light-gray-color);
}

.deleteIcon {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.promptDetails {
  flex: 1;
}

.subCategory {
  font-size: 14px;
  margin-bottom: 8px;
}

.promptContent {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.promptIcons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #888;
}

@media screen and (max-width: 500px) {
  .prompt {
    flex-direction: column;
    align-items: flex-start;
  }
}
