.wrapper {
  width: 100%;
  background-color: var(--black-color);
}

.inner {
  max-width: 1280px;
  color: white;
  display: flex;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
  }
}

.contact {
  display: flex;
  flex-direction: row;

  h5 {
    font-size: 18px;
    color: var(--gray-color);
    padding-bottom: 1rem;
  }

  p {
    font-size: 16px;
    padding-bottom: 0.5rem;
  }

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;

  h5 {
    color: var(--gray-footer);
  }

  @media screen and (max-width: 990px) {
    padding-bottom: 30px;
  }
}

.single {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
    padding-bottom: 20px;
  }
}

.privacy {
  max-width: 1280px;
  margin: 0 auto;
  color: var(--gray-color);
  display: flex;
  padding-bottom: 3rem;

  a {
    font-size: 13px;
    color: var(--gray-footer);
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }

  @media screen and (max-width: 990px) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.footerCopyright {
  @media screen and (max-width: 990px) {
    a {
      padding-bottom: 16px;
    }
  }
}

.privacyRow {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
}

.logo {
  width: 100%;
  max-width: 350px;
}
