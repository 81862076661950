@font-face {
  font-family: "Px Grotesk";
  src: url("./Px-Grotesk-Regular/Px-Grotesk-Regular.woff2") format("woff2"),
    url("./Px-Grotesk-Regular/Px-Grotesk-Regular.woff") format("woff"),
    url("./Px-Grotesk-Regular/Px-Grotesk-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Px Grotesk";
  src: url("./Px-Grotesk-Bold/Px-Grotesk-Bold.woff2") format("woff2"),
    url("./Px-Grotesk-Bold/Px-Grotesk-Bold.woff") format("woff"),
    url("./Px-Grotesk-Bold/Px-Grotesk-Bold.woff2") format("opentype");
  font-weight: 700;
  font-style: normal;
}
