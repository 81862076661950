.wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto 64px auto;
}

@media screen and (max-width: 1450px) {
  .wrapper {
    max-width: unset;
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    padding: 0 16px;
  }

  .hero {
    p {
      width: 100%;
      font-size: 15px;
    }
  }
}

.userPromptWrapper {
  display: flex;

  input {
    background-color: transparent;
    border: 1px solid var(--green-color);
    outline: none;
    padding: 20px 15px;
    min-width: 500px;
    font-size: 15px;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: var(--green-color);
    color: var(--white-color);
    font-weight: 700;
    padding: 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    transition: all 0.2s;
    font-size: 14px;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:hover {
    background-color: var(--green-color);
    color: var(--dark-color);

    span {
      border-color: var(--green-color) var(--green-color) transparent !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .userPromptWrapper {
    width: 100%;

    input {
      min-width: unset;
      width: 100%;
    }

    button {
      width: 180px;
    }
  }
}

.userPrompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 48px 0;
}

.generatedPromptWrapper {
  p {
    font-size: 18px;
    font-weight: bold;
  }
}

.generatedPrompt {
  width: 100%;
  height: 350px;
  border: 1px solid var(--light-gray-color);
  margin: 16px 0 32px 0;
  padding: 20px 15px;
  font-size: 15px;
  transition: all 0.2s;
  background-color: var(--gray-color);
  resize: none;
  outline: none;

  &:hover {
    background-color: #fff;
    cursor: copy;
  }
}

.copyButton {
  outline: none;
  background-color: var(--green-color);
  color: var(--white-color);
  font-weight: 700;
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid var(--green-color);
  transition: all 0.2s;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // &:hover {
  //   background-color: var(--dark-green-color);
  //   color: var(--green-color);
  //   border: 1px solid var(--dark-green-color);
  // }
}

.membershipErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  text-align: center;

  h2 {
    margin-bottom: 16px;
  }

  p {
    font-size: 15px;
  }

  a {
    margin-top: 36px;
    font-size: 15px;
    color: var(--green-color);
  }
}
