.banner {
  background-color: var(--gray-color);
  width: 100%;
  padding: 16px 32px;
  border: 1px solid var(--light-gray-color);
}

.heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.text {
  font-size: 16px;
  // line-height: 1.6em;
}
