.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.promptImage {
  width: 100%;
  height: 200px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.smallPromptImage {
  width: 124px;
  height: 124px;
  border-radius: 4px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.layoutContent {
  padding: 0 100px 40px 100px;
  transform: translateY(-62px);

  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    padding: 3px 2px;
    margin: 16px 0 0 0;
  }
}

.text {
  padding: 3px 2px;
  margin: 0;
  width: 100%;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  margin: 52px 0 0 0;
}

.copyElement {
  width: 100%;
  background-color: var(--gray-color);
  border-radius: 4px;
  padding: 34px 32px;
  position: relative;
  border: 1px solid var(--light-gray-color);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.promptWrapper {
  display: flex;
  gap: 16px;
  margin: 10px 0 30px 0;
}

.editPrompt {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--gray-color);
  margin-top: 36px;
  margin-bottom: 14px;
}

.suggestions {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.suggestion {
  width: 47%;
  display: flex;
  flex-direction: column;
}

.modal {
  width: 960px;
  height: calc(100% - 180px);
  background-color: #fff;
  position: relative;
  overflow: auto;

  label {
    margin-bottom: 8px;
  }

  input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    width: 100%;
  }
}

.variablesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
}

.variables {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 48%;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: var(--dark-green-color);
    color: var(--white-color);
    font-weight: 700;
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    border: 1px solid var(--dark-green-color);
    margin-top: 16px;
    font-size: 14px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      background-color: var(--green-color);
      color: var(--dark-green-color);
      border: 1px solid var(--green-color);
    }

    &.full {
      background-color: var(--green-color);
      color: var(--white-color);
      border: 1px solid var(--green-color);

      // &:hover {
      //   background-color: var(--dark-green-color);
      //   color: var(--green-color);
      //   border: 1px solid var(--dark-green-color);
      // }
    }
  }
}

@media screen and (max-width: 1000px) {
  .modal {
    width: 90%;
  }

  .suggestion {
    width: 100%;
  }

  .layoutContent {
    padding: 0 50px;
  }
}

@media screen and (max-width: 750px) {
  .buttons {
    flex-direction: column;
    gap: 0;
  }

  .closeButton {
    align-self: flex-start;
  }

  .variables {
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
  .buttonsWrapper {
    flex-direction: column;
    gap: 16px;
  }

  .buttons {
    gap: 16px;
  }

  .closeButton {
    width: 100%;
  }

  .layoutContent {
    h1 {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
