.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  width: 500px;
  background-color: #fff;
  padding: 16px 32px;
  position: relative;

  h2 {
    font-size: 22px;
    margin-bottom: 16px;
  }

  p {
    font-size: 15px;
    margin-bottom: 16px;
  }

  label {
    font-size: 16px;
    margin-bottom: 8px;
  }

  input {
    padding: 10px 15px;
    border: 1px solid var(--dark-green-color);
    outline: none;
    font-size: 14px;
  }
}

.categoryEdit {
  margin: 16px 0;

  p {
    font-size: 16px;
  }
}

.buttons {
  display: flex;
  gap: 16px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: var(--dark-green-color);
    color: var(--white-color);
    font-weight: 700;
    padding: 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    border: 1px solid var(--dark-green-color);
    margin-top: 16px;
    font-size: 14px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      background-color: var(--green-color);
      color: var(--dark-green-color);
      border: 1px solid var(--green-color);
    }

    &.full {
      background-color: #fff;
      color: var(--green-color);

      &:hover {
        background-color: var(--green-color);
        color: #fff;
      }
    }
  }
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 580px) {
  .modal {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .buttons {
    flex-direction: column;
    gap: 0;
  }
}
