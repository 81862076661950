.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding-top: 40px;

  p {
    font-size: 17px;
  }
}
