.filtersForm {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.searchInput {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 12px 15px;
  flex: 1;
  font-size: 15px;
  color: var(--dark-green-color);
  border: 1px solid var(--green-color);
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;

  p {
    font-size: 18px;
    font-weight: bold;
  }
}

.activeCategory {
  color: var(--green-color);
}

.category {
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s;
}

.categorySelect {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
}

.searchButton {
  background-color: transparent;
  border: none;
  outline: none;
  background-color: var(--green-color);
  color: var(--white-color);
  font-weight: 700;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  transition: all 0.2s;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--green-color);
    color: var(--dark-color);
  }
}

.midjourneyPromptsFilters {
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 350px;
  // margin-top: 32px;
}

.resetFiltersButton {
  background-color: transparent;
  border: none;
  outline: none;
  background-color: var(--dark-green-color);
  color: var(--white-color);
  font-weight: 700;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  transition: all 0.2s;
  border: 1px solid var(--dark-green-color);
  margin-top: 32px;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--green-color);
    border: 1px solid var(--green-color);

    color: var(--dark-green-color);
  }
}

.mobileCategories {
  display: none;
}

.desktopCategories {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media screen and (max-width: 1000px) {
  .desktopCategories {
    display: none;
  }

  .mobileCategories {
    display: block;
  }
  .midjourneyPromptsPageWrapper {
    padding: 0 32px;
  }

  .midjourneyPromptsPage {
    flex-direction: column;
  }

  .midjourneyPromptsFilters {
    position: static;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 32px;
  }

  .filtersForm {
    width: 400px;
  }

  .categories {
    margin: 0;
    width: 200px;
  }

  .resetFiltersButton {
    width: 150px;
    margin: 0;
  }
}

@media screen and (max-width: 850px) {
  .midjourneyPromptsFilters {
    flex-direction: column;
    align-items: flex-start;
  }

  .filtersForm {
    order: 1;
  }

  .resetFiltersButton {
    order: 2;
  }
}

@media screen and (max-width: 500px) {
  .filtersForm {
    width: 100%;
  }
  .paginationButtonMax {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .midjourneyPromptsPageWrapper {
    padding: 0 16px;
  }
}
