.userPromptWrapper {
  display: flex;
  // width: fit-content;

  input {
    background-color: transparent;
    border: 1px solid var(--green-color);
    outline: none;
    padding: 20px 20px;
    // min-width: 500px;
    width: 100%;
    font-size: 15px;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: var(--green-color);
    color: var(--white-color);
    font-weight: 700;
    padding: 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    transition: all 0.2s;
    font-size: 14px;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:hover {
    background-color: var(--green-color);
    color: var(--dark-color);
  }
}

@media screen and (max-width: 768px) {
  .userPromptWrapper {
    width: 100%;

    input {
      min-width: unset;
      width: 100%;
    }

    button {
      width: 180px;
    }
  }
}
