.paginationButtonMax {
  display: block;
}

.midjourneyPromptsPageWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto 64px auto;
}

.midjourneyPromptsPage {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  margin-top: 21px;
}

.midjourneyPromptsList {
  flex: 1;
}

.midjourneyPromptsCounter {
  font-size: 14px;
}

.loadMoreButton {
  margin-top: 16px;
}

.midjourneyPromptsListWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width: 1450px) {
  .midjourneyPromptsPageWrapper {
    max-width: unset;
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 1000px) {
  .midjourneyPromptsPageWrapper {
    padding: 0 32px;
  }

  .midjourneyPromptsPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .midjourneyPromptsPageWrapper {
    padding: 0 16px;
  }
}
