.userInfoBoxWrapper {
  background-color: var(--green-color);
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.userInfoBox {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: left;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  width: 420px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.userInfoBox p {
  color: #fff;
  margin: 0;
  font-size: 14px;
}

p.userMembership {
  display: flex;
  gap: 6px;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
}

.userInfoBoxHeader {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.showUserInfo {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.accountButton {
  width: 40px;
  height: 40px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  background-color: #fff;
  display: flex;
}

.button {
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px 0;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: transparent;
  text-decoration: none;

  span {
    width: 80%;
    margin: 15px auto 0 auto;
    // font-size: 13px;
    // font-weight: 500;
    font-size: 14px;
    text-align: center;
    height: 40px;
    transition: all 0.2s;
    color: var(--dark-color);
  }

  &:hover {
    span {
      color: var(--green-color);
    }
  }
}

.languageSelector {
  background-color: var(--gray-color);
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 16px 0;
  gap: 16px;
}

@media (max-width: 1450px) {
  .userInfoBox {
    right: 64px;
  }
}

@media screen and (max-width: 1000px) {
  .userInfoBox {
    right: 32px;
  }
}

@media screen and (max-width: 550px) {
  .userInfoBox {
    right: 16px;
  }
}

@media screen and (max-width: 500px) {
  .userInfoBox {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .userInfoBoxWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .userInfoBoxHeader {
    margin: 0;
    margin-top: 16px;
  }
}
