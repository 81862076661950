.paginationButtonMax {
  display: block;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.paginationButton {
  outline: none;
  background-color: var(--white-color);
  color: var(--dark-color);
  cursor: pointer;
  border: 1px solid var(--green-color);
  border: none;
  font-size: 14px;
  padding: 10px 15px;
}

.activePage {
  background-color: var(--green-color);
  color: var(--white-color);
  font-weight: 700;
}

@media screen and (max-width: 500px) {
  .paginationButtonMax {
    display: none;
  }
}
