.membershipErrorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  text-align: center;

  h2 {
    margin-bottom: 16px;
  }

  p {
    font-size: 15px;
  }

  a {
    margin-top: 36px;
    font-size: 15px;
    color: var(--green-color);
  }
}
