.prompt {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid var(--light-gray-color);
  cursor: pointer;
}

.promptImage {
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    border-radius: 5px;
  }
}

.promptDetails {
  flex: 1;
}

.subCategory {
  font-size: 15px;
  margin-bottom: 8px;
}

.promptContent {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.promptIcons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #888;
}

@media screen and (max-width: 500px) {
  .prompt {
    flex-direction: column;
    align-items: flex-start;
  }
}

.placeholderShimmer {
  width: 150px;
  height: 150px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 5px;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}
