.mobile-menu-active {
  color: var(--green-color);
}
.category-accordion {
  .accordion-item {
    // border-bottom: 1px solid #ccc;
    font-size: 14px;

    .accordion-header {
      cursor: pointer;
      padding: 10px;
      // background-color: #f0f0f0;
      // font-weight: bold;
    }

    .accordion-content {
      margin-left: 10px;
      padding: 10px;
      max-height: 200px;
      width: 100%;
      overflow-y: auto;

      ul {
        list-style-type: none;
        padding-left: 0;
      }

      li {
        cursor: pointer;
        padding: 5px 0;
      }
    }
  }
}
