.bannerWrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  background-color: var(--green-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 10px 0;

  p {
    font-weight: 700;
    color: var(--white-color);
  }

  a {
    background-color: #fff;
    text-decoration: none;
    padding: 6px 12px;
    color: var(--dark-color);
    margin-left: 20px;
    font-weight: 700;
  }
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }
}

.textWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1450px) {
  .bannerWrapper {
    max-width: unset;
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 1000px) {
  .bannerWrapper {
    padding: 0 32px;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
  }

  .textWrapper {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;

    a {
      margin: 0;
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 550px) {
  .bannerWrapper {
    padding: 0 16px;
  }
}
