.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 100vh;
}

.logo {
  width: 100%;
  max-width: 250px;
}

.bouncingLoaderWrapper {
  display: flex;
  justify-content: center;
}

.loaderDot {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: var(--green-color);
  //   background-color: #d3d3d3;
  opacity: 1;
  animation: bounceAnimation 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes bounceAnimation {
  to {
    opacity: 0.3;
    transform: translate3d(0, -16px, 0);
  }
}
