.wrapper {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto 64px auto;
}

.userPrompt {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 16px;
  // margin: 48px 0;
}

.promptsPage {
  display: flex;
  flex-direction: row;
  // align-items: flex-start;
  gap: 16px;
  margin-top: 21px;
}

.promptsCounter {
  padding-top: 16px;
  font-size: 14px;
}

.promptsList {
  flex: 1;
}

.promptsListWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width: 1450px) {
  .wrapper {
    max-width: unset;
    width: 100%;
    padding: 0 64px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    padding: 0 32px;
  }

  .promptsPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    padding: 0 16px;
  }
}
